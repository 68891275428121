hr {
  border-top: 1px solid #262a2d;
}

.setTreeSelect-ff {
  width: "100%";
}
.information-container .card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 14px #00000029;
  border-radius: 6px;
  padding: 20px;
  column-gap: 20px;
}

.information-container .card-container .img-icon svg {
  font-size: 40px;
}

.information-container .card-container .card-detail .text-detail {
  font-size: 11px;
  color: #b4b4b4;
}

.information-container .card-container .card-detail .text-header {
  font-size: 13px;
  color: #262a2d;
}

.information-container .card-container p {
  margin: 0;
}
.Col-text-header-Inform {
  font-size: 24px;
  color: #262a2d;
  margin: 0px;
  margin-bottom: 6px;
}
.Col-text-header {
  font-size: 24px;
  color: #262a2d;
  margin: 0px;
  margin-bottom: 20px;
  border-bottom: 1px solid #cfcfcf;
  padding-bottom: 10px;
}
.information-row-margin-bottom {
  margin-bottom: 5px;
}

.Col-text-header-from {
  font-size: 13px;
  color: #262a2d;
  margin: 0px;
  margin-top: 20px;
}

.ant-divider-horizontal.ant-divider-with-text {
  border-top-color: rgb(0 0 0 / 80%);
}

.Col-text-header-Form {
  font-size: 13px;
  color: #ffffff;
  margin-top: 10px;
  padding-left: 10px;
}

.Col-header-background {
  background-color: #1c69be;
}

.gutter-row-Form {
  margin-bottom: 20px;
}

.date-picker-width {
  width: 100%;
}
.ant-select-selector {
  padding: 5px;
}
.select-tab-items {
  align-items: center;
  width: 96%;
}
.select-tab-items .ant-select-selector {
  padding: 5px;
}
.select-tab-items span.ant-select-selection-item-remove {
  display: flex !important;
  align-items: center;
}
.col-flex-end {
  display: flex;
  justify-content: flex-end;
}
.col-hr-header {
  border: 1px solid #cfcfcf;
  opacity: 1;
  margin-bottom: 30px;
}
.col-hr-sub {
  color: var(--unnamed-color-262a2d);
  text-align: left;
  font: normal normal medium 13px/16px Helvetica Neue;
  letter-spacing: 0px;
  color: #262a2d;

  opacity: 1;
}
.Col-text-sub {
  font-size: 13px;
  text-align: left;
  letter-spacing: 0px;
  color: #262a2d;
  margin-bottom: 20px;
  opacity: 1;
}
.Information-card-container {
  border: 1px solid #f0f0f2;
  box-shadow: 0px 3px 10px #0000001a;
  padding: 20px;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  margin-top: 20px;
}
.Information-img-icon {
  font-size: 60px;
  display: flex;
  margin-right: 20px;
}

.information-text-header {
  margin-bottom: 0px;
}
.information-text-detail {
  margin-bottom: 0px;
}

.Information-panding-card {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.Information-setrow-form {
  margin-top: 10px !important;
}
.information-text-header-p {
  text-align: left;
  letter-spacing: 0px;
  color: #262a2d;
  font-size: 13px;
  margin-bottom: 0px;
  opacity: 1;
}
.information-text-sub-p {
  letter-spacing: 0px;
  color: #7c7c7c;
  font-size: 13px;
  margin-bottom: 0px;
  opacity: 1;
}
.information-p-mr-2-background {
  background: brown;
}
.information-inputTextarea-width {
  width: 100%;
}
.information-inputTexta-width {
  width: 100%;
  border-radius: 6px;
  font-size: 13px;
}
.information-inputTexta-width-Button {
  width: 85%;
}
.information-inputTexta-width-inputText-company {
  width: 100%;
  border-radius: 6px 0 0 6px !important;
}
.information-inputTexta-width-Button-Company {
  width: 100%;
}
.information-documentno-justify-conten {
  display: flex;
  justify-content: flex-end;
}
.information-documentno-justify-conten-margin-bottom {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0px;
}
.row-margin-botton {
  margin-bottom: 30px;
}
.pipi-times-color {
  color: #ffff !important;
  margin-top: 4px;
}
.p-tag-value {
  padding: 10px !important;
}
.tag-border {
  border: 1px solid #ced4da;

  width: 100%;
  border-radius: 6px 0 0 6px;
}
.p-p-mr-margin-bottom {
  padding: 4px 47px !important;
  margin-right: 0.5rem !important;
  background: #eef2ff !important;
  color: #4338ca !important;
  border-radius: 6px !important;
  height: 34px;
}
.p-p-mr-margin-bottom-inputgroup {
  margin: 2px 0 2px 2px !important;
  cursor: default !important;
  float: left !important;
  position: relative !important;
  border-radius: 2px !important;
}
.content-width {
  width: 450px;
  overflow-y: scroll;
  height: 250px;
}
.document-justify-content {
  display: flex;
  justify-content: flex-end;
}

.pi-search-color {
  color: #2196f3 !important;
}
.p-inputgrou-position {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  padding: 4px 10px !important;

  background-color: #455a64 !important;
  color: #fff !important;
  font-size: 12px !important;
  line-height: 1.6666667 !important;
  border-radius: 6px !important;
}
@media screen and (max-width: 480px) {
  .information-documentno-justify-conten {
    justify-content: flex-start;
  }
}
.badge-custom {
  border-radius: 15px;
}
p.information-inputTexta-width.information-documentno-justify-conten {
  margin-bottom: 0px;
}
span.p-badge.p-component.p-mr-2.badge-custom {
  font-size: 13px;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
.information-dialog {
  width: 85vw;
  border-radius: 16px;
}
}
@media screen and (min-width: 769px) {
.information-dialog {
  width: 60vw;
  border-radius: 16px;
}
}
.information-dialog .p-dialog-header {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.information-dialog .p-dialog-content {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.information-dialog input.p-inputtext.p-component.set-input-search-dialog {
  border-radius: 6px;
}
.p-dialog .p-dialog-content {
  border-bottom-left-radius: 0;
}
.information-dialog .p-dialog-header {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.information-dialog .p-dialog-content {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.p-datatable.p-datatable-hoverable-rows
  .p-datatable-tbody
  > tr:not(.p-highlight):not(.p-datatable-emptymessage):hover {
  background: #e6e6e6;
}
.p-dropdown:not(.p-disabled):hover {
  border: 1px solid #ced4da;
}
.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none;
}
.information-autoComplete
  ul.p-autocomplete-multiple-container.p-component.p-inputtext {
  border-radius: 6px 0 0 6px;
  border: 1px solid #ced4da;
  box-shadow: none;
}

.information-autoComplete
  .p-autocomplete-panel
  .p-autocomplete-items
  .p-autocomplete-item:hover {
  background: #1a1309;
}
.information-autoComplete li.p-autocomplete-token.p-highlight {
  argin-right: 0.5rem !important;

  overflow: hidden !important;
  text-overflow: ellipsis !important;
  padding: 4px 12px !important;
  background-color: #7c7c7c !important;
  color: #fff !important;
  font-size: 13px !important;
  line-height: 1.6666667 !important;
  border-radius: 6px !important;
  margin-block: 2px;
}
.information-autoComplete-panel
  .p-autocomplete-panel.p-component.p-connected-overlay-enter-done {
  border-radius: 6px;
}
.information-autoComplete-panel li.p-autocomplete-item:hover {
  background: #fbd79d !important;
}
.information-autoComplete
  ul.p-autocomplete-multiple-container.p-component.p-inputtext:hover {
  border: 1px solid #ced4da;
}
.information-autoComplete-panel li.p-autocomplete-item.p-highlight {
  background: #fbd79d !important;
}
button.p-paginator-first.p-paginator-element.p-link.p-disabled {
  color: #f8a51c;
  border: 1px solid;
  height: 32px;
  width: 32px;

  min-width: 32px;
}
button.p-paginator-page.p-paginator-element.p-link.p-paginator-page-start.p-paginator-page-end.p-highlight {
  color: #fcfcfc;
  background: #f8a51c;
}
button.p-paginator-page.p-paginator-element.p-link.p-paginator-page-start.p-highlight {
  color: #fcfcfc;
  background: #f8a51c;
}
.p-link:focus {
  box-shadow: none;
}
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  color: #fcfcfc;
  background: #f8a51c;
}
.p-paginator .p-paginator-pages .p-paginator-page {
  color: #f8a51c;
  border: 1px solid;
  height: 32px;
  width: 32px;
  border-radius: 6px;
  min-width: 32px;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #f8a51c;
  border-color: transparent;
  color: #fcfcfc;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight:hover {
  background: #fcfcfc;
  /* border-color: transparent; */
  border: 1px solid #f8a51c;
  height: 32px;
  color: #f8a51c;
}
.p-dropdown .p-dropdown-label.p-placeholder {
  font-size: 13px !important;
  padding: 0.5rem 0.5rem
}
.badge-display-justify-content-display-none {
  display: initial !important;
}

/* .badge-display-justify-content-display-initial {
  display: none !important;
} */

@media screen and (max-width: 575px) {
  .set-css-status-new {
    align-self: start !important;
  }
  .set-layout-in-row-respone{
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }
  .set-css-doc-code-respone{
    align-self: start !important;
  }
}
.set-css-status-new{
  align-self: flex-end;
}
.set-css-display-flex-status{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding-top: 5px;
}
.set-css-doc-code-respone{
  color: rgb(180, 180, 180);
    font-size: 13px;
    margin-bottom: 1px;
    text-align: end;
}
.set-text-information-require{
  color: red!important;
}
@media screen and (max-width: 500px) {
  .show-more-btn{
    position: absolute;
    background-color: transparent;
    border: transparent;
    color: #f8a51c;
    text-decoration: underline;
    transition: 0.25s;
    right: 50px;
    margin-top: 30px;
    padding: 10px 30px;
  }
}
.show-more-btn{
  position: absolute;
  background-color: transparent;
  border: transparent;
  color: #f8a51c;
  text-decoration: underline;
  transition: 0.25s;
  right: 50px;
  padding: 10px 30px;
}
.show-more-btn:hover{
  background-color: transparent;
  border: transparent;
  color: #282f6a;
  transition: 0.25s;
}

.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 0px;
}