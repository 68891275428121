.pdf-preview-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #e6e6e6;
  align-items: center;
  justify-content: flex-start;
}
.page {
  display: block;
  margin-bottom: -1rem;
}
.page > canvas {
  margin: 1rem;
  box-shadow: 2px 2px 4px #a0a0a0;
}

@media print {
  .pdf-preview-container {
    display: none;
  }
}
