.BTN {
  height: 100%;
  width: 100%;
  /* height: 40px; */
  border: 0px;
  /* margin-bottom: 10px; */
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 13px;
  padding-left: 10px;
  padding-right: 12px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: left;
  border-radius: 7px;
  background-color: #e6e6e6 padding-box;
  color: black;
}
.BTN svg {
  font-size: 18px;
}
.BTN:hover {
  background-color: #9ca0c3;
  color: white;
  transition: 0.2s;
}

.BTN:focus {
  background-color: #282f6a;
  color: white;
}
.BTNActive {
  background-color: #282f6a;
  color: white;
}
.button-position {
  position: absolute;
  width: 246px;
  height: 40px;
  left: 40px;
  top: 156px;

  background: #282f6a;
  border-radius: 7px;
}

.floating-button {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 40px;
  background-color: #f8a51c;
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  outline: none;
  width: 50px;
  height: 50px;
  @media (max-width: 600px) {
    display: block;
  }
}
